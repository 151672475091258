import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { About } from '../models/about.model';


@Injectable({
    providedIn: 'root'
})


export class AboutService {
    public aboutSubject: BehaviorSubject<About[]> = new BehaviorSubject<About[]>([] as About[]);
    about!: About[];

    constructor(
        private http: HttpClient
    ) { }

    setAboutSubject() {
        this.aboutSubject.next(this.about);
    }

    setAbout(about: About[]): void {
        this.about = about;
        this.setAboutSubject();
    }

    getAboutJsonData(): Observable<any> {
        const url = `/assets/content/about.json?nocache=${new Date().getTime()}`;
        return this.http.get<any>(url);
    }

    getAboutObservable(): Observable<About[]> {
        return this.aboutSubject.asObservable();
    }



}